<template>
    <div class="aboutSoyeg">
        <div class="aboutSoyeg-title">
            <div class="back" @click="backClick">
                <img src="@/assets/images/personal/z.png" />
                <span>Back</span>
            </div>
            <h5>About SOYEGO</h5>
        </div>
        <div class="aboutSoyeg-main">
            <div>
                <img src="@/assets/images/personal/soyegp.png">
            </div>
            <div class="text">
                <span>SOYEGO is an international fashion brand. We offer fashion-forward styles to our customers around the world. We differ ourselves with other fast fashion brands by focusing on quality. Our factories have produced products for high end brands for more than 3 decades. Meticulous measures are taken starting from raw materials to final product before shipment. SOYEGO's selection includes pieces from wardrobe essentials to offerings directly from runaway shows. </span>
                <span>SOYEGO empowers customers to enjoy high fashion, high quality with affordable price.</span>
            </div>
        </div>
    </div>
</template>

<style>
.about-content{
    background: #FFFFFF;
}
</style>
<style lang="scss" scoped>
.aboutSoyeg {
    width: 100%;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;

    .aboutSoyeg-title {
        width: 100%;
        text-align: center;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 20px;
        padding-bottom: 40px;

        .back {
            position: absolute;
            display: flex;
            align-items: center;
            cursor: pointer;
            left: 20px;

            span {
                font-size: 14px;
                font-family: Montserrat,SF-UI;
                font-weight: Regular;
                color: #330000;
                padding-left: 6px;
            }
        }

        h5 {
            font-size: 30px;
            font-family: Montserrat,SF-UI;
            font-weight: bold;
            color: #330000;
        }
    }

    .aboutSoyeg-main{
        width: 100%;
        padding-bottom: 177px;
        display: flex;
        flex-direction: column;
        align-items: center;
        
        >div{
            width: 55%;

            span{
                font-size: 14px;
                font-family: Montserrat,SF-UI;
                font-weight: 400;
                color: #330000;
                line-height: 32px;
            }
        }
        .text{
            padding-top: 30px;
        }
    }
}
</style>

<script>
export default {
    name: "aboutSoyeg",
    components: {

    },
    data: () => {
        return {
            
        }
    },
    created() { },
    mounted() { },
    methods: {
        backClick() {
            this.$router.go(-1);
        }
    }
}
</script>
